import React from 'react'
import htmlSerializer from '../utils/htmlSerializer'

export default ({ data }) => {
  return (
    <div>

      <div
      className="vimeo__embed__container">
        <iframe
          src={"https://player.vimeo.com/video/" + data.clip[0].embed_html[0].text + "?color=8560a8&title=0&byline=0&portrait=0"}
          className="vimeo__iframe"
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen>
        </iframe>
      </div>
      <script src="https://player.vimeo.com/api/player.js"></script>
    </div>
  )
}
